<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card author-box card-primary">
            <div class="card-body">
              <div class="author-box-left">
                <img
                  alt="image"
                  :src="user.data.avatar"
                  width="100px"
                  height="100px"
                  class="rounded-circle author-box-picture"
                />
                <div class="clearfix"></div>
              </div>
              <div class="author-box-details">
                <div class="author-box-name">
                  <a href="#">{{ user.data.username }}</a>
                </div>
                <div class="author-box-job">{{ user.data.email }}</div>
                <div class="author-box-description">
                  <div class="table-striped">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Username</td>
                          <th scope="row">: {{ user.data.username }}</th>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <th scope="row">: {{ user.data.email }}</th>
                        </tr>
                        <tr>
                          <td>Role</td>
                          <th scope="row">: {{ user.data.role.name }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      name: "Profile",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
